<template>
  <v-skeleton-loader
    type="image"
    v-if="!value"
  />
  <v-card v-else>
    <v-card-title>{{$t('t.LetterDematerializedSettings')}}</v-card-title>
    <v-card-text>
      <picker
        document-types="providers"
        :clearable="true"
        :label="$t('t.ElectronicLettersProvider')"
        :selected-id.sync="computedAr24ProviderId"
        :dense="false"
        :disabled="!isCompany"
        can-send-letter-dematerialized
      />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {
    Picker: () => import('@/components/picker')
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataAr24ProviderId: null
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ar24ProviderId: this.dataAr24ProviderId
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  computed: {
    computedAr24ProviderId: {
      get () {
        return this.dataAr24ProviderId
      },
      set (v) {
        this.dataAr24ProviderId = v
        this.dataDocumentEmit()
      }
    }
  },
  props: {
    value: Object,
    isCompany: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler (v) {
        this.dataAr24ProviderId = v?.ar24ProviderId
      },
      immediate: true
    },
    isCompany: {
      handler (v) {
        if (!v) {
          this.dataAr24ProviderId = null
          this.dataDocumentEmit()
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.v-text-field
  margin-right 1em
</style>
