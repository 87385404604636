import { render, staticRenderFns } from "./letter-dematerialized.vue?vue&type=template&id=f8f5d3ba&scoped=true&"
import script from "./letter-dematerialized.vue?vue&type=script&lang=js&"
export * from "./letter-dematerialized.vue?vue&type=script&lang=js&"
import style0 from "./letter-dematerialized.vue?vue&type=style&index=0&id=f8f5d3ba&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f5d3ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardText,VCardTitle,VSkeletonLoader})
